export default class AppLoginModule
{
    static init(): void
    {
        AppLoginModule.createCookie('userTimeZone', (new Date()).getTimezoneOffset().toString(), 0);
        AppLoginModule.initTextFieldSubmit();
    }

    /**
     *
     * @param name
     * @param value
     * @param days
     */
    private static createCookie(name: string, value, days: number)
    {
        let expires = '';
        if (days)
        {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = "" + name + "=" + value + expires + "; path=/";
    }

    private static initTextFieldSubmit()
    {
        let inputList = $('#login-form').find('input').toArray();
        for (let input of inputList)
        {
            $(input).on('keydown', (e: JQueryEventObject) =>
            {
                if (e.keyCode == 13)
                {
                    $('#login-form').submit();
                }
            });
        }
    }
}
